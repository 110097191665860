import React, { useState } from "react";
import { FaShareAlt, FaGift, FaCopy, FaShare } from "react-icons/fa";
import Layouts from "../../Layout/Layouts";
import { FaMessage } from "react-icons/fa6";

const ReferEarn = () => {
  const [copied, setCopied] = useState(false);
  const referralCode = "GTU1253";

  const handleCopy = () => {
    navigator.clipboard.writeText(referralCode);
    setCopied(true);
    setTimeout(() => setCopied(false), 2000);
  };

  return (
    <Layouts>
      <div className=" min-h-screen flex items-center justify-center p-4">
        <div className="bg-white rounded-lg shadow-xl p-6 md:p-8 max-w-md w-full">
          <h1 className="text-3xl font-bold text-center text-gray-800 mb-6">
            Share & Earn Rewards
          </h1>

          <div className="flex items-center justify-center mb-8">
            <div className="bg-green-100 rounded-full p-4">
              <FaShareAlt className="text-4xl text-green-600" />
            </div>
          </div>

          <p className="text-gray-600 text-center mb-6">
            Invite friends and earn amazing rewards when they join using your
            unique referral code!
          </p>

          <div className="bg-gray-100 rounded-lg p-4 flex items-center justify-between mb-6">
            <span className="font-mono text-lg text-gray-700">
              {referralCode}
            </span>
            <button
              onClick={handleCopy}
              className="bg-green-600 text-white px-4 py-2 rounded-md hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-offset-2 transition-colors duration-200"
              aria-label="Copy referral code"
            >
              {copied ? "Copied!" : <FaCopy />}
            </button>
          </div>

          <div className="space-y-4">
            <h2 className="text-xl font-semibold text-gray-800">
              How it works:
            </h2>
            <div className="flex items-start space-x-3">
              <div className="bg-green-100 rounded-full p-2 mt-1">
                <FaShareAlt className="text-green-600" />
              </div>
              <p className="text-gray-600">
                Share your unique referral code with friends
              </p>
            </div>
            <div className="flex items-start space-x-3">
              <div className="bg-blue-100 rounded-full p-2 mt-1">
                <FaGift className="text-blue-600" />
              </div>
              <p className="text-gray-600">
                Your friends get a special discount on their first purchase
              </p>
            </div>
            <div className="flex items-start space-x-3">
              <div className="bg-green-100 rounded-full p-2 mt-1">
                <FaGift className="text-green-600" />
              </div>
              <p className="text-gray-600">
                You earn rewards for each successful referral
              </p>
            </div>
          </div>
      
          <div className="bg-muted p-4 flex justify-center gap-2">
              <button>
              <FaShare className="h-5 w-5" />
              <span className="sr-only">Share referral</span>
            </button>
              <button>
              <FaMessage className="h-5 w-5" />
              <span className="sr-only">Share referral via message</span>
            </button>
          </div>
          <div className="mt-8 text-center">
            <button className="bg-gradient-to-r from-green-600 to-green-600 text-white px-6 py-3 rounded-full font-semibold hover:from-green-700 hover:to-green-700 focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-offset-2 transition-all duration-200 transform hover:scale-105">
              Share Now
            </button>
          </div>
        </div>
      </div>
    </Layouts>
  );
};

export default ReferEarn;
