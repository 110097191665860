import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";

import BottomSheet from "../Helpers/BottomSheet";
import Layouts from "../../Layout/Layouts";
import {
  FaCar,
  FaCommentDots,
  FaDollarSign,
  FaFilter,
  FaPhone,
  FaStar,
} from "react-icons/fa";
import { useParams } from "react-router-dom";

import ProductCard from "./MachineCard";
import { DateRange } from "react-date-range";
import { setOrderData } from "../../context/Reducer/OrderSlice";
import {
  getGeoLocation,
  getNearbyMachines, SimpleLoader
} from "../../utils/utils";

const SelectMachine = () => {
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [products, setProducts] = useState([]);
  const [isBookingSheetOpen, setIsBookingSheetOpen] = useState(false);
  const [driver, setDriver] = useState(null);
  const [isDriverFound, setIsDriverFound] = useState(false);
  const [isSearchingDriver, setIsSearchingDriver] = useState(false);
  const [selectedStartDate, setSelectedStartDate] = useState(null);
  const [step, setStep] = useState(1);
  const [dateRange, setDateRange] = useState([
    {
      startDate: new Date(),
      endDate: new Date(),
      key: "selection",
    },
  ]);
  const { category } = useParams();
  const Categories = decodeURIComponent(category);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);

  const fetchByNearbyMachines = async () => {
    setIsLoading(true);
    const [latitute, longitude] = await getGeoLocation();
    const nearbyMachines = await getNearbyMachines(
      latitute,
      longitude,
      600,
      Categories,
      10
    );
    setProducts(nearbyMachines);
    setIsLoading(false);
  };
  const handleProductClick = (product) => {
    setSelectedProduct(product);
  };

  const handleBookNow = () => {
    setIsSearchingDriver(true);
    setIsBookingSheetOpen(true);

    // Simulate driver search
    setTimeout(() => {
      // Mock driver details
      const foundDriver = {
        name: "John Doe",
        vehicle: "Toyota Prius",
        license: "ABC-1234",
        mobile: "XXXX-XXXX-XX12", // Partially hidden
      };

      setDriver(foundDriver);
      setIsDriverFound(true);
      setIsSearchingDriver(false);
      setStep(2); // Move to the next step to show driver details
    }, 5000); // Simulated delay of 5 seconds
  };
  const handleBackClick = () => {
    setStep((prevStep) => prevStep - 1);
  };

  const handlePartialPayment = () => {
    const orderData = {
      productId: selectedProduct.id,
      productName: selectedProduct.name,
      price: selectedProduct.price,
      Prebookprice: selectedProduct.price * 0.03,
      ownerId: selectedProduct.ownerID,
      img: selectedProduct.machineImage,
      startdate: selectedStartDate ? selectedStartDate.toDateString() : "",
    };

    dispatch(setOrderData(orderData));
    navigate(`/Checkout`);
    // Implement payment logic here
  };

  const handleDateChange = (ranges) => {
    const { startDate } = ranges.selection;
    setSelectedStartDate(startDate);
  };
  useEffect(() => {
    fetchByNearbyMachines();
  }, [Categories]);

  return (
    <Layouts>
      <div className="bg-white flex flex-col h-screen">
        {/* Search and Results */}
        <div className="bg-white rounded-xl -mt-6 p-4 space-y-4">
          <div className="flex-1 overflow-y-auto pb-20">
            {/* Products */}
            <div className="flex items-center lg:mb-2 lg:px-4  lg:pt-4">
             
              <h2 className="p-4 font-semibold lg:text-2xl">{Categories}</h2>
              <div></div>
            </div>

            <div className="w-full overflow-x-auto py-2 text-xs lg:text-sm">
              <div className="flex space-x-2 lg:space-x-4 lg:px-4">
                <button className="flex items-center justify-center px-4 py-2 bg-white border border-gray-300 rounded-full text-gray-700 whitespace-nowrap">
                  <FaFilter className="mr-2" />
                  Sort
                </button>
                <button className="flex items-center justify-center px-4 py-2 bg-white border border-gray-300 rounded-full text-gray-700 whitespace-nowrap">
                  Rating 4.0+
                </button>
                <button className="flex items-center justify-center px-4 py-2 bg-white border border-gray-300 rounded-full text-gray-700 whitespace-nowrap">
                  Favourites
                </button>
                <button className="flex items-center justify-center px-4 py-2 bg-white border border-gray-300 rounded-full text-gray-700 whitespace-nowrap">
                  Near By
                </button>
                {/* Add more buttons as needed */}
              </div>
            </div>
            {category && products.length > 0 && (
              <div className="px-2 py-3 mt-3">
                <div className="grid  lg:grid-cols-3 gap-3">
                  {products.map((product, index) => (
                    <ProductCard
                    distance={product.distance}
                      key={product.id}
                      product={product}
                      selectedProduct={selectedProduct}
                      handleProductClick={handleProductClick}
                    />
                  ))}
                </div>
              </div>
            )}
          </div>
          {isLoading && (
            <div className="flex justify-center items-center">
              <SimpleLoader />
            </div>
          )}
          {/* Fixed bottom section with DateRangePicker and Book Now button */}
          {selectedProduct && products.length > 0 && (
            <div className="fixed bottom-0 left-0 right-0 z-40 bg-white border-t border-gray-200 shadow-md">
              <div className="p-4 flex  gap-3">
                <button
                  onClick={handleBookNow}
                  className="w-full bg-green-800 hover:bg-green-900 text-white py-2 rounded-lg text-lg font-semibold"
                >
                  Book Now
                </button>
              </div>
            </div>
          )}
          {/* <AdsCarousel slides={featuresSlides} /> */}

          <BottomSheet
            isOpen={isBookingSheetOpen}
            onClose={() => setIsBookingSheetOpen(false)}
          >
            <div className="flex space-x-3 space-y-2 flex-col items-center justify-center">
              {step === 1 && (
                <>
                  <h2 className="text-3xl font-bold mb-4">Choose a Date</h2>
                  <div className="mb-6">
                    <DateRange
                      editableDateInputs={true}
                      onChange={handleDateChange}
                      moveRangeOnFirstSelection={false}
                      ranges={dateRange}
                    />
                  </div>
                  <button
                    onClick={() => setStep(2)}
                    className="bg-green-700 text-white px-3 py-3 rounded-lg hover:bg-green-800 w-full"
                  >
                    Next
                  </button>
                </>
              )}
              {step === 2 && (
                <div className=" rounded-lg p-2 w-full">
                  <h2 className="text-lg font-bold text-gray-700">
                    Order Summary
                  </h2>
                  {driver && (
                    <>
                      <div className="    overflow-hidden ">
                        <div className="bg-white border border-gray-300 rounded-lg">
                          <div className="flex items-center p-4">
                            <img
                              className="w-16 h-16 rounded-full"
                              src="https://via.placeholder.com/64"
                              alt="Driver"
                            />
                            <div className="ml-4 flex-1">
                              <h2 className="text-xl font-semibold">
                                Ravana Rao
                              </h2>
                              <div className="flex items-center text-sm text-gray-500">
                                <FaStar className="text-yellow-500 mr-1" />
                                4.8 (127)
                              </div>
                            </div>
                            <div className="flex space-x-3">
                              <button className="w-8 h-8 flex items-center justify-center bg-gray-200 rounded-full">
                                <FaPhone className="text-gray-600" />
                              </button>
                              <button className="w-8 h-8 flex items-center justify-center bg-gray-200 rounded-full">
                                <FaCommentDots className="text-gray-600" />
                              </button>
                            </div>
                          </div>
                          <div className="px-4 py-2 border-t border-b flex flex-row justify-between">
                            <div className="flex items-center">
                              <FaCar className="text-gray-600 mr-2" />
                              <span className="text-gray-700">CLMV069</span>
                            </div>
                            <div className="text-gray-500">
                              Claas Belt Havetser
                            </div>
                          </div>
                          <div className="px-4 py-2 flex justify-between items-center">
                            <div className="flex justify-between">
                              <span className="text-gray-600">Address:</span>
                              <span className="text-gray-800">
                                123 Main St, City, Country
                              </span>
                            </div>
                          </div>
                        </div>
                        <div className="px-4 py-4 bg-white border rounded-lg border-gray-300 mt-3">
                          <div className="flex justify-between items-center">
                            <div className="text-lg font-semibold">
                              Total Fare
                            </div>
                            <div className="text-xl font-bold text-green-600">
                              ₹1045
                            </div>
                          </div>
                          <div className="flex items-center mt-2">
                            <FaDollarSign className="text-green-600 mr-2" />
                            <div className="text-gray-600">
                              <div className="font-semibold">Cash</div>
                              <div className="text-sm">
                                Pay when the ride ends
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </>
                  )}
                  <div className="flex flex-row space-x-2 mt-4">
                    <button
                      onClick={handleBackClick}
                      className="bg-gray-500 text-white px-3 py-3 rounded-lg hover:bg-gray-600 w-full"
                    >
                      Back
                    </button>
                    <button
                      onClick={handlePartialPayment}
                      className="bg-green-700 text-white px-3 py-3 rounded-lg hover:bg-green-800 w-full"
                    >
                      Book Now
                    </button>
                  </div>
                </div>
              )}
            </div>
          </BottomSheet>
        </div>
      </div>
    </Layouts>
  );
};

export default SelectMachine;
