import React, { lazy, Suspense } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { Provider } from "react-redux";
import store from "./context/store";
import Loader from "./Layout/Loader";
import "./App.css";
import "react-date-range/dist/styles.css"; // main css file
import "react-date-range/dist/theme/default.css"; // theme css file
import SelectMachine from "./Components/Service/SelectMachine";
import RequestDelete from "./Components/Auth/RequestDelete";
import ChooseLocation from "./Components/Home/ChooseLocation";
import MyWallet from "./Components/Account/MyWallet";
import ReferEarn from "./Components/Account/ReferEarn";
import CropPackageList from "./Components/Home/CropPackage";
import MachineSelection from "./Components/Home/ViewCropPackage";
import QRCodeScanner from "./Components/Helpers/QRScanner";

const Home = lazy(() => import("./Pages/Home"));
const Auth = lazy(() => import("./Components/Auth/Auth"));
const RegisterUserData = lazy(() => import("./Components/Auth/RegisterUserData"));
const Services = lazy(() => import("./Pages/Services"));
const MyProfile = lazy(() => import("./Components/Account/MyProfile"));
const ContactUs = lazy(() => import("./Pages/Policy/ContactUs"));
const PrivacyPolicy = lazy(() => import("./Pages/Policy/PrivacyPolicy"));
const RefundCancellationPolicy = lazy(() => import("./Pages/Policy/RefundCancellationPolicy"));
const ShipandDelivery = lazy(() => import("./Pages/Policy/ShipandDelivery"));
const TermsandConditions = lazy(() => import("./Pages/Policy/TermsandConditions"));
const ThankYouPage = lazy(() => import("./Components/Helpers/ThankYou"));
const UserFeedback = lazy(() => import("./Components/Helpers/UserFeedback"));
const ErrorPage = lazy(() => import("./Components/Helpers/Errorpage"));
const CheckOut = lazy(() => import("./Components/CheckOut/CheckOut"));
const ViewOrders = lazy(() => import("./Components/Orders/ViewOrders"));
const EqupimentsBuy = lazy(() => import("./Components/CheckOut/EqupimentsBuy"));
const History = lazy(() => import("./Pages/History"));
const Account = lazy(() => import("./Pages/Account"));
const OwnerPage = lazy(() => import("./Pages/OwnerPage"));

function App() {
  return (
    <Provider store={store}>
      <BrowserRouter>
        <Suspense fallback={<Loader />}>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/auth" element={<Auth />} />
            <Route path="/category" element={<Services />} />
            <Route path="/machines/:category" element={<SelectMachine/>} />
            <Route path="/equpiments/:id" element={<EqupimentsBuy />} />
            <Route path="/combo" element={<CropPackageList/>} />
            <Route path="/crop-packages/:packageid" element={<MachineSelection/>} />
            <Route path="/profile" element={<MyProfile />} />
            <Route path="/Checkout" element={<CheckOut />} />
            <Route path="/Provider/:ownerId" element={<OwnerPage />} />
            <Route path="/account" element={<Account />} />
            <Route path="/history" element={<History />} />
            <Route path="/viewOrders" element={<ViewOrders />} />
            <Route path="/RegisterUserData" element={<RegisterUserData />} />
            <Route path="/ChooseLocation" element={<ChooseLocation/>} />
            <Route path="/myWallet" element={<MyWallet/>} />
            <Route path="/qrScanner" element={<QRCodeScanner/>} />


            <Route path="/referEarn" element={<ReferEarn/>} />
            <Route path="/helpcenter" element={<MyWallet/>} />
            <Route path="/thankyou" element={<ThankYouPage />} />
            <Route path="/Contact-Us" element={<ContactUs />} />
            <Route path="/Privacy-Policy" element={<PrivacyPolicy />} />
            <Route path="/Refund-Cancellation" element={<RefundCancellationPolicy />} />
            <Route path="/Shipment-Delivery" element={<ShipandDelivery />} />
            <Route path="/Terms-Condition" element={<TermsandConditions />} />
            <Route path="/feedback" element={<UserFeedback />} />
            <Route path="/Request-Delete-Account" element={<RequestDelete/>} />
            <Route path="*" element={<ErrorPage />} />
          </Routes>
        </Suspense>
      </BrowserRouter>
    </Provider>
  );
}

export default App;
