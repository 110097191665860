import React, { useState, useEffect, Fragment } from "react";
import Logo from "../asset/Home/Logo.png";
import letterlogo from "../asset/Home/logo-growto-secondary.png";
import { Link, NavLink, useLocation, useNavigate } from "react-router-dom";
import {
  MdLocationOn,
  MdOutlineDomainAdd,
  MdOutlineQrCodeScanner,
} from "react-icons/md";
import { FiShoppingCart } from "react-icons/fi";
import { RxAvatar } from "react-icons/rx";
import { PiShareNetworkDuotone } from "react-icons/pi";
import { Listbox } from "@headlessui/react";
import { Dialog } from "@headlessui/react";
import "../App.css";
import Footer from "./Footer";
import BottomNavigator from "./BottomNavigator";
import { HiSearch } from "react-icons/hi";
import { IoChevronBackOutline } from "react-icons/io5";
import account from "../asset/Home/Profile/farmer.png";
import { isAuth } from "../context/Routes/isAuth";
import { motion, AnimatePresence } from "framer-motion";
import { useAuth } from "../config/AuthProvider";
import { db } from "../config/FirebaseConfig";
import axios from "axios";

import { doc, updateDoc } from "firebase/firestore";
import { FaLocationArrow } from "react-icons/fa";
import { FaMapLocation } from "react-icons/fa6";
export default function Layouts({ children }) {
  const [show, setShow] = useState(false);

  const [profile, setProfile] = useState(false);
  const DEFAULT_DISTRICT = "Cuddalore";
  const [scrolled, setScrolled] = useState(false);
  const [prevScrollPos, setPrevScrollPos] = useState(0);
  const scrollThreshold = 0.5; // 50% of the page height
  const location = useLocation();
  const handleShare = async () => {
    if (navigator.share) {
      try {
        await navigator.share({
          title: "Share Current Page",
          url: window.location.href,
        });
      } catch (error) {
        console.error("Error sharing:", error);
      }
    } else {
      console.log("Web Share API is not supported in this browser.");
      // Implement fallback behavior or UI for browsers that don't support Web Share API
    }
  };

  const [isSearchOpen, setIsSearchOpen] = useState(false);

  const handleSearchClick = () => {
    setIsSearchOpen(!isSearchOpen);
  };
  // Check if the current path is the home page
  const isHomePage = location.pathname === "/";
  const navigate = useNavigate();

  const handleGoBack = () => {
    // Navigate back one page
    navigate(-1);
  };
  const handleScroll = () => {
    const currentScrollPos = window.scrollY;
    const scrollDistance = currentScrollPos - prevScrollPos;
    const pageHeight =
      document.documentElement.scrollHeight - window.innerHeight;
    const scrollPercentage = currentScrollPos / pageHeight;

    setScrolled(scrollPercentage > scrollThreshold && scrollDistance > 0);
    setPrevScrollPos(currentScrollPos);
  };
  const [isOpen, setIsOpen] = useState(false);
  const [fullAddress, setFullAddress] = useState("");
  const [currentLocation, setCurrentLocation] = useState(null);
  const refreshLocation = () => {
    setCurrentLocation(null);
    setFullAddress("");
    setIsOpen(false);

    // Fetch location again
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        async (position) => {
          const { latitude, longitude } = position.coords;
          try {
            const response = await axios.get(
              `https://maps.googleapis.com/maps/api/geocode/json?latlng=${latitude},${longitude}&key=AIzaSyBirV-sNOTkIpxr1CEcxA5mOaSLlJZ15GA`
            );
            if (response.data.results && response.data.results.length > 0) {
              const address = response.data.results[0].formatted_address;
              setCurrentLocation(address.split(",")[0]); // Show only the first line
              setFullAddress(address); // Store the full address

              // Extract district from address
              const addressComponents =
                response.data.results[0].address_components;
              const districtComponent = addressComponents.find((component) =>
                component.types.includes("administrative_area_level_2")
              );
            } else {
              setCurrentLocation("Address not found");
            }
          } catch (error) {
            console.error("Error fetching address:", error);
            setCurrentLocation("Error fetching address");
          }
        },
        (error) => {
          console.error("Error getting location:", error);
          setCurrentLocation("Error getting location");
        }
      );
    } else {
      setCurrentLocation("Geolocation not supported");
    }
  };
  useEffect(() => {
    const getCurrentLocation = () => {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
          async (position) => {
            const { latitude, longitude } = position.coords;
            try {
              const response = await axios.get(
                `https://maps.googleapis.com/maps/api/geocode/json?latlng=${latitude},${longitude}&key=AIzaSyBirV-sNOTkIpxr1CEcxA5mOaSLlJZ15GA`
              );
              if (response.data.results && response.data.results.length > 0) {
                const address = response.data.results[0].formatted_address;
                const addressParts = address.split(",");
                setCurrentLocation(addressParts.slice(0, 2).join(", "));
                setFullAddress(address); // Store the full address

                // Extract district from address
                const addressComponents =
                  response.data.results[0].address_components;
                const districtComponent = addressComponents.find((component) =>
                  component.types.includes("administrative_area_level_2")
                );

                if (districtComponent) {
                } else {
                }
              } else {
                setCurrentLocation("Address not found");
              }
            } catch (error) {
              console.error("Error fetching address:", error);
              setCurrentLocation("Error fetching address");
            }
          },
          (error) => {
            console.error("Error getting location:", error);
            setCurrentLocation("Error getting location");
          }
        );
      } else {
        setCurrentLocation("Geolocation not supported");
      }
    };

    getCurrentLocation();
  }, []);

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [prevScrollPos]);

  const { currentUser, loading } = useAuth();

  return (
    <>
      <div className="absolute  w-full h-full">
        {/* desktop */}

        <div>
          <header
            className={`z-20 hidden sm:block sticky top-0 transition-all ${
              scrolled ? "-translate-y-full" : "translate-y-0"
            }`}
          >
            <div
              className={`w-full mx-auto bg-white shadow py-1 ${
                scrolled ? "sticky" : ""
              }`}
            >
              <nav className="container  px-6 justify-between h-16 flex items-center lg:items-stretch mx-auto">
                <div className="h-full flex items-center">
                  <div className="mr-10 flex gap-3 items-center justify-center">
                    <Link to="/" className="  ">
                      <img
                        loading="eager"
                        fetchpriority="auto"
                        src={Logo}
                        className="w-7 h-8"
                        alt="logo"
                      />
                    </Link>
                    <Link to="/" className="   ">
                      <img
                        loading="eager"
                        fetchpriority="auto"
                        src={letterlogo}
                        className="w-full h-8"
                        alt="logo"
                      />
                    </Link>
                  </div>
                  <ul className="pr-12 xl:flex items-center h-full hidden">
                    <li>
                      <NavLink
                        to="/"
                        className="py-2 px-4 text-sm text-gray-600 hover:text-gray-800 transition duration-150 ease-in-out border-b-2 border-transparent hover:border-green-700"
                        activeclassname="text-green-700 border-green-700"
                      >
                        Home
                      </NavLink>
                    </li>
                    <li>
                      <NavLink
                        to="/category"
                        className="py-2 px-4 text-sm text-gray-600 hover:text-gray-800 transition duration-150 ease-in-out border-b-2 border-transparent hover:border-green-700 mx-10"
                        activeclassname="text-green-700 border-green-700"
                      >
                        Category
                      </NavLink>
                    </li>
                    <li>
                      <NavLink
                        to="/history"
                        className="py-2 px-4 text-sm text-gray-600 hover:text-gray-800 transition duration-150 ease-in-out border-b-2 border-transparent hover:border-green-700 mr-10"
                        activeclassname="text-green-700 border-green-700"
                      >
                        Order
                      </NavLink>
                    </li>
                    <li>
                      <NavLink
                        to="/account"
                        className="py-2 px-4 text-sm text-gray-600 hover:text-gray-800 transition duration-150 ease-in-out border-b-2 border-transparent hover:border-green-700"
                        activeclassname="text-green-700 border-green-700"
                      >
                        Account
                      </NavLink>
                    </li>
                  </ul>
                </div>
                <div className="h-full xl:flex items-center justify-end hidden">
                  <div className="w-full h-full flex items-center">
                    <div className="w-full  h-full flex items-center">
                      <div className="mr-4 w-full relative "></div>
                    </div>
                    <div className="w-full h-full flex">
                      <div
                        aria-haspopup="true"
                        className="cursor-pointer w-full flex items-center justify-end relative"
                        onClick={() => setProfile(!profile)}
                      >
                        <img
                          loading="eager"
                          fetchpriority="auto"
                          className="rounded-lg h-10 w-10 object-cover"
                          src="https://track2traininginstitute.files.wordpress.com/2021/07/apj.jpg?w=540"
                          alt="logo"
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="visible xl:hidden flex items-center relative">
                  <ul className="p-2 w-64 border-r bg-white absolute top-0 -ml-2 rounded right-0 shadow mt-12 lg:mt-16 hidden">
                    <li className="cursor-pointer text-gray-600 text-sm leading-3 tracking-normal py-2 hover:text-green-700 focus:text-green-700 focus:outline-none">
                      <div className="flex items-center">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          className="icon icon-tabler icon-tabler-user"
                          width={20}
                          height={20}
                          viewBox="0 0 24 24"
                          strokeWidth="1.5"
                          stroke="currentColor"
                          fill="none"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        >
                          <path stroke="none" d="M0 0h24v24H0z" />
                          <circle cx={12} cy={7} r={4} />
                          <path d="M6 21v-2a4 4 0 0 1 4 -4h4a4 4 0 0 1 4 4v2" />
                        </svg>
                        <span className="ml-2">Profile</span>
                      </div>
                    </li>
                    <Link to="/">
                      <li className="flex xl:hidden cursor-pointer text-gray-600 text-sm leading-3 tracking-normal mt-2 py-2 hover:text-green-700 focus:text-green-700 focus:outline-none">
                        <div className="flex items-center">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            className="icon icon-tabler icon-tabler-grid"
                            width={20}
                            height={20}
                            viewBox="0 0 24 24"
                            strokeWidth="1.5"
                            stroke="currentColor"
                            fill="none"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          >
                            <path stroke="none" d="M0 0h24v24H0z" />
                            <rect x={4} y={4} width={6} height={6} rx={1} />
                            <rect x={14} y={4} width={6} height={6} rx={1} />
                            <rect x={4} y={14} width={6} height={6} rx={1} />
                            <rect x={14} y={14} width={6} height={6} rx={1} />
                          </svg>
                          <span className="ml-2">Home</span>
                        </div>
                      </li>
                    </Link>
                    <Link to="/">
                      <li className=" xl:hidden  cursor-pointer text-gray-600 text-sm leading-3 tracking-normal mt-2 py-2 hover:text-green-700 focus:text-green-700 focus:outline-none flex items-center relative">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          className="icon icon-tabler icon-tabler-help"
                          width={20}
                          height={20}
                          viewBox="0 0 24 24"
                          strokeWidth="1.5"
                          stroke="currentColor"
                          fill="none"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        >
                          <path stroke="none" d="M0 0h24v24H0z" />
                          <circle cx={12} cy={12} r={9} />
                          <line x1={12} y1={17} x2={12} y2="17.01" />
                          <path d="M12 13.5a1.5 1.5 0 0 1 1 -1.5a2.6 2.6 0 1 0 -3 -4" />
                        </svg>
                        <span className="ml-2">Products</span>
                      </li>
                    </Link>
                    <li className="flex xl:hidden cursor-pointer text-gray-600 text-sm leading-3 tracking-normal mt-2 py-2 hover:text-green-700 flex items-center focus:text-green-700 focus:outline-none">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="icon icon-tabler icon-tabler-settings"
                        width={20}
                        height={20}
                        viewBox="0 0 24 24"
                        strokeWidth="1.5"
                        stroke="currentColor"
                        fill="none"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      >
                        <path stroke="none" d="M0 0h24v24H0z" />
                        <path d="M10.325 4.317c.426-1.756 2.924-1.756 3.35 0a1.724 1.724 0 0 0 2.573 1.066c1.543-.94 3.31.826 2.37 2.37a1.724 1.724 0 0 0 1.065 2.572c1.756.426 1.756 2.924 0 3.35a1.724 1.724 0 0 0 -1.066 2.573c.94 1.543-.826 3.31-2.37 2.37a1.724 1.724 0 0 0 -2.572 1.065c-.426 1.756-2.924 1.756-3.35 0a1.724 1.724 0 0 0 -2.573 -1.066c-1.543.94-3.31-.826-2.37-2.37a1.724 1.724 0 0 0 -1.065 -2.572c-1.756-.426-1.756-2.924 0-3.35a1.724 1.724 0 0 0 1.066 -2.573c-.94-1.543.826-3.31 2.37-2.37.996.608 2.296.07 2.572-1.065z" />
                        <circle cx={12} cy={12} r={3} />
                      </svg>
                      <span className="ml-2">Performance</span>
                    </li>
                  </ul>
                  <svg
                    onClick={() => setShow(!show)}
                    aria-label="Main Menu"
                    aria-haspopup="true"
                    xmlns="http://www.w3.org/2000/svg"
                    className="icon icon-tabler icon-tabler-menu"
                    width={32}
                    height={32}
                    viewBox="0 0 24 24"
                    strokeWidth="1.5"
                    stroke="#2c3e50"
                    fill="none"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  >
                    <path stroke="none" d="M0 0h24v24H0z" />
                    <line x1={4} y1={8} x2={20} y2={8} />
                    <line x1={4} y1={16} x2={20} y2={16} />
                  </svg>
                </div>
              </nav>
            </div>
          </header>

          {/* mobile statusbar */}
          {isHomePage ? (
            <header
              className={` top-0 sm:hidden block z-30 rounded-b-lg p-2 w-full mx-auto bg-white  py-1 sticky `}
            >
              <div className=" mt-2 container  mx-auto h-auto  flex   flex-col mb-2  px-2">
                <div className="flex flex-row justify-between items-center ">
                  {/* Logo */}

                  <Link to="/" className="   ">
                    <img
                      loading="eager"
                      fetchpriority="auto"
                      src={letterlogo}
                      className="w-full h-8"
                      alt="logo"
                    />
                  </Link>
                  {/* <div
        className=" px-4 py-2  flex items-center cursor-pointer"
        onClick={() => setIsOpen(true)}
      >
        <MdLocationOn className="w-5 h-5   mr-2"/>
        <span>{currentLocation ? currentLocation : 'Getting location...'}</span>
      </div> */}

                  {currentUser ? (
                    <div className="flex items-center">
                      {/* Profile Photo */}
                      <Link to="/account" className="lg:flex items-center">
                        <img
                          className="w-8 h-8 rounded-full ring-2 ring-gray-300 hover:bg-white"
                          src={account}
                          alt="Bordered avatar"
                        />
                      </Link>
                    </div>
                  ) : (
                    // Login Button
                    <Link
                      to="/auth"
                      className="flex justify-center items-center bg-green-50 h-8 rounded-lg"
                    >
                      <span className="mx-4 text-green-700 font-semibold">
                        Login
                      </span>
                    </Link>
                  )}
                </div>
                {/* <Link
                  to="/category"
                  className="pt-2 mt-3 relative text-gray-600"
                >
                  <input
                    className="border-2 border-gray-300 w-full bg-white h-12 px-5 pr-16 rounded-lg text-sm focus:outline-none"
                    type="search"
                    name="search"
                    placeholder="Search"
                  />
                  <button
                    type="submit"
                    className="absolute right-0 top-0 mt-5 mr-4"
                  >
                    <FiSearch className="text-gray-600 h-4 w-4 fill-current" />
                  </button>
                </Link> */}
              </div>
            </header>
          ) : (
            <header className="top-0 sm:hidden block bg-white sticky rounded-b-lg p-3">
              <div className="p-0 flex items-center w-full justify-between">
                {/* Back Button */}
                <div
                  className="text-black cursor-pointer hover:text-gray-800"
                  onClick={handleGoBack}
                >
                  <IoChevronBackOutline size={24} />
                </div>
                <div>
                  <div
                    className="bg-white px-4 py-2 rounded-full shadow flex items-center cursor-pointer"
                    onClick={() => setIsOpen(true)}
                  >
                    <span className="w-2 h-2 bg-green-500 rounded-full mr-2"></span>
                    <span>
                      {currentLocation
                        ? currentLocation
                        : "Getting location..."}
                    </span>
                  </div>

                  <Dialog
                    open={isOpen}
                    onClose={() => setIsOpen(false)}
                    className="fixed inset-0 z-10 overflow-y-auto"
                  >
                    <div className="flex items-center justify-center min-h-screen">
                      <Dialog.Overlay className="fixed inset-0 bg-black opacity-30" />

                      <div className="bg-white z-50 rounded max-w-sm mx-auto p-6 space-y-4">
                        <Dialog.Title className="text-lg font-semibold">
                          Your Current Location
                        </Dialog.Title>
                        <Dialog.Description>{fullAddress}</Dialog.Description>

                        <button
                          className="w-full bg-green-500 text-white py-2 rounded hover:bg-green-700"
                          onClick={refreshLocation}
                        >
                          Refresh Location
                        </button>

                        <button
                          className="w-full bg-gray-300 text-black py-2 rounded hover:bg-gray-400"
                          onClick={() => setIsOpen(false)}
                        >
                          Close
                        </button>
                      </div>
                    </div>
                  </Dialog>
                </div>

                <div className="flex items-center space-x-4">
                  <Link
                    to="/qrScanner"
                    className="text-gray-800 cursor-pointer hover:text-gray-800"
                  >
                    <MdOutlineQrCodeScanner
                      size={24}
                      className="text-gray-600 "
                    />
                  </Link>
                  <div
                    className="text-black cursor-pointer hover:text-gray-800"
                    onClick={handleShare}
                  >
                    <PiShareNetworkDuotone size={24} />
                  </div>
                </div>
              </div>
            </header>
          )}

          <main className="pb-[72px] sm:pb-0 bg-[#f3f4f6] ">{children}</main>
          <Footer />

          {/* bg-[#f3f4f6]  */}
          {/* <Link to="/chatbot">x fc vd 
            <img    loading="eager"
                fetchpriority="auto" 
              src={chat}
              className="w-[90px] h-[70px] fixed bottom-[40px] z-30  right-[30px] text-green-600 z-100 animate-float "
              alt="chat"
            />
          </Link> */}

          <BottomNavigator />
        </div>
      </div>
    </>
  );
}
