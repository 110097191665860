import React from "react";
import { Link, useLocation } from "react-router-dom";
import { AiOutlineHome, AiOutlineSetting, AiOutlineSearch, AiOutlineUser } from "react-icons/ai";
import { BiSolidCategory } from 'react-icons/bi';
import { FaHistory } from 'react-icons/fa';
import image from "../asset/bg.png";

const menuItems = [
  { path: "/", icon: AiOutlineHome, label: "Home" },
  { path: "/category", icon: BiSolidCategory, label: "Services" },
  { path: "/history", icon: FaHistory, label: "Activity" },
  { path: "/account", icon: AiOutlineUser, label: "Account" },
];

function BottomNavigator() {
  const location = useLocation();
  const pathname = location.pathname;

  const isActive = (path) => {
    return pathname === path;
  };

  // Define an array of paths where the BottomNavigator should be hidden
  const hiddenPaths = ["/checkout", "/buynow","/equpiments"];

  // Check if the current path starts with any path in the hiddenPaths array
  const isHidden = hiddenPaths.some((hiddenPath) => pathname.startsWith(hiddenPath));

  // If the BottomNavigator should be hidden, return null
  if (isHidden) {
    return null;
  }

  return (
    <nav className="fixed bottom-0 left-0 z-20 w-full lg:hidden">
        <img src={image} className="w-full" alt="" />
      <div className="flex justify-between items-center bg-white shadow-lg px-3 py-1.5  ">
        {menuItems.map(({ path, icon: Icon, label }) => (
          <Link
            key={path}
            to={path}
            className={`flex flex-col items-center cursor-pointer p-1 ${isActive(path) ? " rounded border-t-4 border-green-700" : ""}`}
          >
            <Icon size={24} color={isActive(path) ? "green" : "gray"} />
            <span className={`text-xs mt-1 ${isActive(path) ? "text-green-700" : "text-gray-500"}`}>
              {label}
            </span>
          </Link>
        ))}
      </div>
    </nav>
  );
}

export default BottomNavigator;
